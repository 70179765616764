<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ total_count }}</h2>
                <p class="fontsize-sm m-0">
                  Total Submitted
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ total_pending }}</h2>
                <p class="fontsize-sm m-0">
                  Total Pending
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ total_processing }}</h2>
                <p class="fontsize-sm m-0">
                  Total Processing
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ total_processed }}</h2>
                <p class="fontsize-sm m-0">
                  Total Processed
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ total_invalid }}</h2>
                <p class="fontsize-sm m-0">
                  Total Invalid
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { LeadSupervisorDashboardService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'LeadSupervisorDashboard',

  middleware: ['auth', 'lead-supervisor'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  mixins: [formatter],

  data () {
    return {
      total_count: 0,
      total_pending: 0,
      total_processing: 0,
      total_processed: 0,
      total_invalid: 0
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {
    async getStatistics () {
      await LeadSupervisorDashboardService.getStatistics()
        .then(({ data }) => {
          this.total_count = data.total_count
          this.total_pending = data.total_pending
          this.total_processing = data.total_processing
          this.total_processed = data.total_processed
          this.total_invalid = data.total_invalid
        })
    }
  }
}
</script>
